exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./assests/fonts/Agustina.woff"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./assests/fonts/Montserrat-Regular.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./assests/fonts/GoogleSans-Bold.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./assests/fonts/GoogleSans-BoldItalic.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./assests/fonts/GoogleSans-Italic.ttf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./assests/fonts/GoogleSans-Medium.ttf"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./assests/fonts/GoogleSans-MediumItalic.ttf"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./assests/fonts/GoogleSans-Regular.ttf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: \"Agustina Regular\";\n  font-style: normal;\n  font-weight: normal;\n  src: local(\"Agustina Regular\"),\n    url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: local(\"Montserrat\"),\n    url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Google Sans Bold\";\n  src: local(\"Google Sans Bold\"),\n    url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Google Sans Bold Italic\";\n  src: local(\"Google Sans Bold Italic\"),\n    url(" + ___CSS_LOADER_URL___3___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Google Sans Italic\";\n  src: local(\"Google Sans Italic\"),\n    url(" + ___CSS_LOADER_URL___4___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Google Sans Medium\";\n  src: local(\"Google Sans Medium\"),\n    url(" + ___CSS_LOADER_URL___5___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Google Sans Medium Italic\";\n  src: local(\"Google Sans Medium Italic\"),\n    url(" + ___CSS_LOADER_URL___6___ + ") format(\"woff\");\n}\n@font-face {\n  font-family: \"Google Sans Regular\";\n  src: local(\"Google Sans Regular\"),\n    url(" + ___CSS_LOADER_URL___7___ + ") format(\"woff\");\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\nhtml,\nbody {\n  scroll-behavior: smooth;\n}\nbody {\n  margin: 0;\n  font: 19px / 23px Montserrat, \"Montserrat\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n/* Media Query */\n@media (max-width: 1380px) {\n  html,\n  body {\n    font-size: 16px;\n    line-height: normal;\n  }\n}\n", ""]);

